<template>
    <div class="login_wrapper">
        <div class="login-content_wrapper">
            <div>
                <vs-input
                        class="w-full no-icon-border login_input"
                        data-vv-validate-on="blur"
                        icon="icon icon-user"
                        icon-pack="feather"
                        label-placeholder="Username"
                        name="username"
                        v-model="loginData.username"
                    />
                <span class="text-danger text-sm">{{ errors.first('email') }}</span>
            </div>
            <div>
                <vs-input
                        class="w-full mt-6 no-icon-border"
                        data-vv-validate-on="blur"
                        icon="icon icon-lock"
                        icon-pack="feather"
                        label-placeholder="Password"
                        name="password"
                        type="password"
                        v-model="loginData.password"
                     />
                <span class="text-danger text-sm">{{ errors.first('password') }}</span>

            </div>
            <div class="login_button_wrapper">
                <div :disabled="!validateForm" @click="login" class="login-button">Login</div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        data() {
            return {
                user_name: '',
                user_id: localStorage.getItem('user_id'),

                loginData: {
                    password: '',
                    passphrase: '',
                    passphrase_id: '',
                    user_id: '',
                    jwt: '',
                    merchant_id: ''
                },
                checkbox_remember_me: false,
                store:'',
                store_id:'',
                merchant_id: localStorage.getItem('merchant_id'),
                storeList: []
            }
        },
        computed: {
            validateForm() {
                return !this.errors.any() && this.loginData.user_id !== '' && this.loginData.password !== '';
            },
        },
        methods: {
            login() {

                // this.$http.post("http://192.168.1.40:4000/user/sign_in", {
                this.$http.post("https://v2.sandbox-api.buybuddy.co/user/sign_in", {
                    credentials: {
                        id: this.loginData.user_id,
                        password: this.loginData.password,
                    },
                }).then((response) => {
                 //   console.log(response.data.data);
                    if (parseInt(response.status / 100) === 2) {
                        this.loginData.user_id = response.data.user_id;
                        this.loginData.passphrase = response.data.data.passphrase;
                        this.loginData.merchant_id = response.data.data.merchant_id;
                        this.merchant_id = response.data.data.merchant_id;
                     //   console.log("merchant_id:", response.data.data.merchant_id);

                        //     this.$http.post("http://192.168.1.40:4000/user/sign_in/token", {
                        this.$http.post("https://v2.sandbox-api.buybuddy.co/user/sign_in/token", {
                            passphrase_submission: {
                                passphrase: this.loginData.passphrase,
                            },

                        }).then((tokenResponse) => {
                            const responseData = tokenResponse.data.data;
                            this.loginData.user_id = responseData.user_id;
                            this.loginData.jwt = responseData.jwt;
                            this.loginData.passphrase_id = responseData.passphrase_id;
                         //   const base64Url = responseData.jwt.split('.')[1];
                       //     const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                         //   const jwtValue = JSON.parse(window.atob(base64));

                            /*console.log(jwtValue);*/
                            localStorage.setItem('jwt', this.loginData.jwt);
                            localStorage.setItem('passphrase', this.loginData.passphrase);
                            localStorage.setItem('user_id', this.loginData.user_id);
                            localStorage.setItem('passphrase_id', this.loginData.passphrase_id);
                            localStorage.setItem('merchant_id', this.loginData.merchant_id);
                            localStorage.setItem('store', this.store);
                            localStorage.setItem('store_id', this.store_id);
                    //        console.log("login", tokenResponse.data);


                            //this.setAuth(true);
                            this.$auth().loggedIn = true;

                            this.userName();
                         //   console.log("user id:", localStorage.getItem('user_id'));
                            this.$http.get(`https://v2.sandbox-api.buybuddy.co/user/${localStorage.getItem('user_id')}/permission_set`, this.config
                                //         this.$http.get(`http://192.168.1.40:4000/user/${localStorage.getItem('user_id')}/permission_set`,this.config
                            ).then((responsePermission) => {
                                console.log("permission check:", responsePermission.data.data);
                                const perList = [];
                                responsePermission.data.data.map((item) => {
                                    perList.push(item.name);

                                });

                                localStorage.setItem("permissions", perList.join("|"));
                            });

                        })

                    }

                }).catch(() => {
                    this.$vs.notify({
                        time: 8000,
                        title: 'Giriş Başarılı olamadı !',
                        text: 'Daha sonra tekrar deneyin',
                        color: 'danger',
                        icon: 'query_builder'
                    });

                })


            },
            notifyAlreadyLogedIn() {
                this.$vs.notify({
                    title: 'Login Attempt',
                    text: 'You are already logged in!',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'warning'
                });
            },
            userName() {
                this.$http.get("https://v2.sandbox-api.buybuddy.co/user/" + this.loginData.user_id + "/username", this.config
                ).then(response => {
                    this.user_name = response.data.data.name;
                    localStorage.setItem('user_name', this.user_name);
                    this.$router.push('/');
                });

            },

        }
    }
</script>
<style lang="scss">
    .login_wrapper {
        display: flex;
        flex-direction: column;
        margin: auto auto;
        vertical-align: center;
        height: 100vh;
    }

    .login-content_wrapper {
        margin: auto auto;
        vertical-align: center;
        padding-top: 20px;
        padding-right: 30px;
        border-radius: 5px;
    }

    .login_button_wrapper {
        text-align: center;
        margin-top: 15px;
    }

    .login-button {
        border-radius: 5px;
        text-align: center;
        line-height: 40px;
        width: 95px;
        cursor: pointer;
        background: #a59cd4;
        color: #3e3694;
        margin-left: 52px;
    }

    body {
        background: white;
    }

    .login_wrapper {

        background-size: 900px 700px;

    }

    @media only screen and (max-width: 767px)
        // start of Phone styles
    {
        .login_wrapper {
            background-size: 600px 500px;

        }
    }

    @media only screen and (min-width: 768px) and (max-width: 1024px)
        // start of Tablets styles *!
    {
        .login_wrapper {
            background-size: 900px 700px;

        }
    }

    @media only screen and (min-width: 1524px) {
        //start of Large styles *!
        .login_wrapper {
            background-size: 900px 700px;

        }
    }
</style>
